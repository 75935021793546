


.v-whats-new-info-wrapper {
    max-width: 1440px;
    margin: auto;
    width: 100%;
  
    @media screen and (max-width: 1440px) {
      max-width: 1200px;
    }

    @media screen and (max-width: 1024px) {
      max-width: calc(100% - 40px);
    }

}