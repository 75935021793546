.v-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 9999;

    &.hide {
        display: none;
    }
}