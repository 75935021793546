@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
    background-color: #f2f7ff;
    margin: 0;
}

$color_1: #03045e;
$color_2: #fff;
$font-family_1: 'Plus Jakarta Sans';
$background-color_1: #fff;
$background-color_2: #2d74ff;

.a-header {
    max-width: 1600px;
    margin: auto;
    padding: 24px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hamburger-btn {
        display: none;
        font-size: 30px;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    .close-btn {
        display: none;
        position: fixed;
        right: 35px;
        top: 18px;
        font-size: 72px;
        background: transparent;
        border: none;
        height: 42px;
        cursor: pointer;
    }


    .a-nav-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            gap: 51px;

            a {
                cursor: pointer;
                color: $color_1;
                font-family: $font-family_1;
                font-size: 20px;
                font-weight: 500;
                padding: 20px 0;
                display: flex;
                text-decoration: none;
            }

            a.btn {
                display: flex;
                padding: 14px 32px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: $background-color_2;
                color: $color_2;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .a-header {
        padding: 20px;

        .a-logo-wrapper img {
            width: 120px;
        }

        .hamburger-btn {
            display: block;
        }

        .a-nav-wrapper {
            position: fixed;
            background: $background-color_1;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            transform: translateX(100%);
            transition: transform 0.3s ease-in-out;
        }

        .a-nav-wrapper.show-nav {
            transform: translateX(0);
            align-items: normal;

            ul{
                text-align: left;
                gap: 0px;
                margin-top: 50px;
                padding-left: 20px;

                li {
                    a{
                        justify-content: flex-start;

                        &:last-child {
                            width: fit-content;
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .close-btn {
            display: block;
        }

        ul {
            flex-direction: column;
            margin-top: 80px;
            width: 100%;
            flex-wrap: nowrap;
            gap: 20px;

            li,
            a {
                width: 100%;
                justify-content: center;
                padding: 0;
            }

            li.btn,
            a.btn {
                padding: 10px 0;
                width: calc(100% - 40px);
                margin-left: 20px;
            }
        }
    }
}