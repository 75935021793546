.about-page {
  $color_1: #03045e;
  $color_2: #ff4c98;
  $color_3: rgba(3, 4, 94, 0.7);
  $color_4: #fff;
  $color_5: #3a2771;
  $color_6: #4b4d8e;
  $color_7: #414141;
  $color_8: #000;
  $color_9: #94a3b8;
  $font-family_1: "Plus Jakarta Sans";
  $background-color_1: transparent;
  $background-color_2: #ffd1e5;

  .wrapper {

    .a-section1 {
      margin: 0;

      .wrapper {
        display: flex;
        justify-content: center;
        gap: 30px;
        margin: auto;
        align-items: end;
        background: linear-gradient(180deg,
            rgba(255, 255, 255, 0) 41.18%,
            #fff 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0) 41.18%, #fff 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0) 41.18%, #fff 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0) 41.18%, #fff 100%),
          url("../../assets/images/image19.png"),
          lightgray 0px -16.007px / 100% 123.451% no-repeat;
        height: 884px;
        max-width: 1500px;
        background-size: cover;

        div {
          text-align: center;
        }
      }

      h1 {
        color: $color_1;
        text-align: center;
        font-size: 62.769px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        letter-spacing: -1.883px;
        margin: auto;

        span {
          color: $color_2;
        }
      }

      p {
        color: $color_3;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 158%;
        margin: 38px auto;
        max-width: 1040px;
      }

      a.btn {
        display: inline-flex;
        width: 176px;
        padding: 14px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #2d74ff;
        color: $color_4;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-decoration: none;
        margin-bottom: 64px;
      }
    }

    .a-section2 {
      margin: 129px 20px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        max-width: 1200px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 32px;
        border-left: 8px solid #ff4c98;

        h2 {
          margin: 0;
          color: $color_1;
          font-size: 38px;
          font-style: normal;
          font-weight: 700;
          line-height: 124%;
          letter-spacing: -1.14px;
        }

        p {
          margin: 0;
          color: $color_3;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 158%;
        }

        .images{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;
        }
      }
    }

    .a-section3 {
      padding: 76px 20px;
      background: #3a2771;

      .wrapper {
        display: flex;
        max-width: 1400px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;

        .cards-wrapper {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          .card {
            width: calc(50% - 30px);
            border-radius: 10px;
            border-radius: 26px;
            border: 9px solid #fff;
            background-size: cover;
            background-position: center;
            background: linear-gradient(210deg,
                rgba(255, 255, 255, 0) 34.08%,
                #fff 90.45%),
              linear-gradient(210deg, rgba(255, 255, 255, 0) 34.08%, #fff 90.45%),
              linear-gradient(210deg, rgba(255, 255, 255, 0) 34.08%, #fff 90.45%),
              url("../../assets/images/image18.png"),
              lightgray -212.971px 0px / 131.509% 101.008% no-repeat;
            height: 595px;
            padding: 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: end;
            gap: 0;
            background-size: cover;
            background-position: center;

            h3 {
              color: $color_5;
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: 124%;
              letter-spacing: -0.96px;
            }

            p {
              color: $color_5;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 158%;
              margin-top: 23px;
            }
          }

          .card.card2 {
            background: linear-gradient(210deg,
                rgba(255, 255, 255, 0) 34.08%,
                #fff 90.45%),
              linear-gradient(210deg, rgba(255, 255, 255, 0) 34.08%, #fff 90.45%),
              linear-gradient(210deg, rgba(255, 255, 255, 0) 34.08%, #fff 90.45%),
              url("../../assets/images/image24.png"),
              lightgray -212.971px 0px / 131.509% 101.008% no-repeat;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }

    .a-section4 {
      margin: 60px 20px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1400px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;

        h2 {
          color: $color_1;
          font-size: 48.77px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          letter-spacing: -1.463px;
          max-width: 653px;
          margin: auto;

          span {
            color: $color_2;
            position: relative;
            margin-left: 10px;

            svg {
              position: absolute;
              bottom: -10px;
              left: 4px;
              width: 100%;
            }
          }
        }

        .carousel-wrapper {
          margin-top: 50px;
          overflow: hidden;

          .image-carousel {
            display: flex;
            align-items: center;

            .item {
              width: 100%;
              min-width: 100%;
              display: flex;
            }

            img {
              border-radius: 10px;
            }
          }

          .content-wrapper {
            display: flex;
            align-items: center;

            .leftDesignWrapper {
              width: 20%;
              position: relative;
            }

            .rightDesignWrapper {
              width: 20%;
              position: relative;
              display: flex;
              justify-content: end;
            }

            .leftDesign {
              height: 236px;
              width: 134px;
              border-radius: 51px;
              background: #3a2771;
              z-index: 1;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            }

            .rightDesign {
              height: 236px;
              width: 134px;
              border-radius: 51px;
              background: #3a2771;
              z-index: 1;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            }

            .leftButton {
              position: absolute;
              top: 35%;
              background-color: $background-color_1;
              border: none;
              cursor: pointer;
              z-index: 1;
              right: 20px;
            }

            .rightButton {
              position: absolute;
              top: 35%;
              background-color: $background-color_1;
              border: none;
              cursor: pointer;
              z-index: 1;
              left: 20px;
            }

            .content-carousel {
              padding: 40px 0;
              display: flex;
              width: 60%;
              transition: all 0.5s;

              .item {
                width: 100%;
                min-width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                p {
                  max-width: 860px;
                  color: $color_6;
                  text-align: center;
                  font-family: $font-family_1;
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 158%;
                  margin: 0;
                }

                img {
                  width: auto;
                }

                .name {
                  width: 100%;
                  text-align: center;
                  color: $color_1;
                  font-family: $font-family_1;
                  font-size: 28.548px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 144%;
                  letter-spacing: -0.856px;
                  padding: 25px 0;
                }
              }

              .item.active {
                opacity: 1;
              }
            }
          }
        }
      }

      .image-carousel {
        .owl-item {
          transform: scale(0.85);
        }

        .owl-item.active.center {
          transform: scale(1);
        }
      }
    }

    .a-section6 {
      margin: 80px 20px 80px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1400px;
        margin: auto;

        .left {
          .img-wrapper {
            position: relative;

            .content-wrapper {
              position: absolute;
              bottom: 0;
              width: calc(100% - 20px);
              border-radius: 20px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              background: #3a2771;
              padding: 32px;
              margin: 0 10px 10px;
              display: flex;
              gap: 20px;
              align-items: center;

              p {
                margin: 0;
              }

              .icon {
                background-color: $background-color_2;
                height: 66px;
                width: 66px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  margin-left: -4px;
                  margin-top: 3px;
                }
              }

              .type2 {
                color: $color_4;
                font-family: $font-family_1;
                font-size: 20.743px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: -0.622px;
                margin-top: 15px;
                max-width: 330px;
              }
            }
          }
        }

        .right {
          max-width: 599px;
          margin: auto;

          h2 {
            color: $color_1;
            font-family: $font-family_1;
            font-size: 48px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            letter-spacing: -1.44px;
            margin-top: 0;

            span {
              color: $color_2;
            }
          }

          .input-wrapper {
            width: 100%;

            label {
              width: 100%;
              color: $color_7;
              font-family: $font-family_1;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 160%;
              letter-spacing: -0.3px;

              span {
                color: $color_2;
              }
            }

            input {
              width: 100%;
              display: flex;
              padding: 12px 20px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 10px;
              border: 1px solid rgba(203, 214, 231, 0);
              background: #fff;
              color: $color_8;
              font-family: $font-family_1;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              letter-spacing: -0.27px;
              margin: 8px 0 30px;

              &::placeholder {
                color: $color_9;
              }
            }

            textarea {
              width: 100%;
              display: flex;
              padding: 12px 20px;
              align-items: center;
              gap: 8px;
              align-self: stretch;
              border-radius: 10px;
              border: 1px solid rgba(203, 214, 231, 0);
              background: #fff;
              color: $color_8;
              font-family: $font-family_1;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%;
              letter-spacing: -0.27px;
              margin: 8px 0 30px;

              &::placeholder {
                color: $color_9;
              }
            }
          }

          button {
            border-radius: 9px;
            background: #2d74ff;
            height: 76px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: $color_4;
            font-family: $font-family_1;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.6px;
            border: none;
            margin-top: 7px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about-page {
    .wrapper {
      .a-section1 {
        margin: 20px;

        h1 {
          font-size: 42px;
        }

        p {
          font-size: 20px;
          padding: 0 20px;
          margin: 16px auto;
        }

        a.btn {
          margin-bottom: 24px;
        }
      }

      .a-section2 {
        margin: 40px 20px;

        .wrapper {
          gap: 20px;

          h2 {
            font-size: 24px;
          }

          p {
            font-size: 18px;
          }
        }
      }

      .a-section3 {
        margin: 0;
        padding: 40px 0;

        .wrapper {
          margin: 20px;

          .cards-wrapper {
            .card {
              width: calc(50% - 20px);

              h3 {
                font-size: 20px;
              }

              p {
                font-size: 16px;
              }
            }
          }
        }
      }

      .a-section4 {
        margin: 0;
        padding: 40px 0;

        .wrapper {
          margin: 0;

          h2 {
            font-size: 30px;
            margin-bottom: 20px;

            svg {
              display: none;
            }
          }

          .carousel-wrapper {
            .content-wrapper {
              .leftDesignWrapper {
                width: 15%;
              }

              .rightDesignWrapper {
                width: 15%;
              }

              .leftButton {
                right: -13px;
              }

              .rightButton {
                left: -13px;
              }

              .leftDesign {
                width: 70px;
              }

              .rightDesign {
                width: 70px;
              }

              .content-carousel {
                width: 70%;
              }
            }
          }
        }
      }

      .a-section6 {
        margin: 20px;

        .wrapper {
          flex-direction: column;

          img {
            max-width: 100%;
          }

          .right {
            h2 {
              font-size: 30px;
              margin: 10px 20px 30px;
            }

            .input-wrapper {
              margin: 0 20px;
            }

            button {
              margin: 0 20px;
            }
          }

          .left {
            .img-wrapper {
              .content-wrapper {
                width: 100%;
                padding: 10px;
                margin: 0;
                border-radius: 10px;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .about-page {
    .wrapper {
      .a-section1 {
        h1 {
          font-size: 24px;
        }

        p {
          font-size: 18px;
        }
      }

      .a-section3 {
        .wrapper {
          .cards-wrapper {
            gap: 0;

            .card {
              width: calc(100%);
              margin-bottom: 20px;
            }
          }
        }
      }

      .a-section4 {
        .wrapper {
          .carousel-wrapper {
            .content-wrapper {
              .leftDesignWrapper {
                width: 20%;
              }

              .rightDesignWrapper {
                width: 20%;
              }

              .leftButton {
                right: -13px;
              }

              .rightButton {
                left: -13px;
              }

              .leftDesign {
                width: 40px;
              }

              .rightDesign {
                width: 40px;
              }

              .content-carousel {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
}