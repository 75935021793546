.home-page {

  .wrapper {

    .a-heading1 {
      color: #03045e;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 62.769px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
      width: 60%;
      letter-spacing: -1.883px;
      margin: 66px auto 26px;

      .edit {
        color: #ff4c98;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          left: 150px;
          top: px;
        }
      }
    }

    .a-heading2 {
      max-width: 840px;
      margin: auto;
      color: rgba(3, 4, 94, 0.7);
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 158%;
      /* 37.92px */
    }

    .a-section1 {
      margin-top: 60px;
      margin: 60px 20px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1400px;
        margin: auto;
        align-items: center;

        .img-wrapper {
          position: relative;

          img {
            border-radius: 10px;
            transition: all 0.5s;
          }

          .content-wrapper {
            position: absolute;
            bottom: 30px;
            padding: 0 25px;
            width: 100%;

            p {
              color: #03045e;
              font-family: "Plus Jakarta Sans";
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              /* 26.4px */
              letter-spacing: -0.66px;
            }

            a {
              color: #ff4c98;
              font-family: "Plus Jakarta Sans";
              font-size: 18px;
              font-style: normal;
              font-weight: 800;
              line-height: 120%;
              /* 21.6px */
              letter-spacing: -0.54px;
              text-decoration: none;
            }

            a.btn {
              border-radius: 9px;
              background: #2d74ff;
              color: #fff;
              font-size: 20px;
              font-weight: 700;
              letter-spacing: -0.6px;
              height: 76px;
              display: inline-flex;
              align-items: center;
              padding: 0 23px;
              width: 100%;
              justify-content: space-between;
            }
          }

          img:hover {
            transform: scale(1.03);
          }

          .content-wrapper.content-wrapper-1 {
            max-width: 325px;
          }

          .content-wrapper.content-wrapper-3 {
            max-width: 390px;
          }
        }

        .img1 {
          margin-top: -50px;
        }

        .img3 {
          margin-top: 50px;
        }
      }
    }

    .a-section2 {
      margin-top: 60px;
      margin: 60px 20px;

      .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1400px;
        margin: auto;
        align-items: center;

        .left {
          h2 {
            color: #03045e;
            font-size: 48.77px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            /* 58.524px */
            letter-spacing: -1.463px;
            max-width: 653px;

            span {
              color: #ff4c98;
              position: relative;
              margin-left: 10px;

              svg {
                position: absolute;
                bottom: -10px;
                left: -8px;
              }
            }
          }

          p {
            color: #4b4d8e;
            font-family: "Plus Jakarta Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 158%;
            /* 37.92px */
            margin: 16px auto 48px;
          }

          a.btn {
            display: inline-flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            background: #2d74ff;
            color: #fff;
            font-family: "Plus Jakarta Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 24px */
            letter-spacing: -0.6px;
            text-decoration: none;
          }
        }

        .right {
          .img-wrapper {
            position: relative;

            .content-wrapper {
              position: absolute;
              bottom: 0;
              width: 450px;
              border-radius: 23px;
              border-top-left-radius: 0;
              border-bottom-right-radius: 0;
              background: #3a2771;
              padding: 43px 55px;

              p {
                margin: 0;
              }

              .type1 {
                color: #ff8abc;
                font-family: "Plus Jakarta Sans";
                font-size: 54.251px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 65.101px */
                letter-spacing: -1.628px;
              }

              .type2 {
                color: #fff;
                font-family: "Plus Jakarta Sans";
                font-size: 20.743px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 24.891px */
                letter-spacing: -0.622px;
                margin-top: 15px;
                max-width: 330px;
              }
            }
          }
        }
      }
    }

    .a-section3 {
      padding: 76px 20px;
      background: #3a2771;

      .wrapper {
        display: flex;
        max-width: 1400px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;

        h2 {
          color: #fff;
          text-align: center;
          font-family: "Plus Jakarta Sans";
          font-size: 48px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          /* 57.6px */
          letter-spacing: -1.44px;
          max-width: 600px;
          margin: auto;
          margin-bottom: 65px;

          span {
            color: #ff4c98;
            position: relative;

            svg {
              position: absolute;
              bottom: -10px;
              left: 15px;
            }
          }
        }

        .cards-wrapper {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;

          .card {
            width: calc(33.33% - 20px);
            border-radius: 10px;
            background: #f0f5ff;

            img {
              width: 100%;
              border-top-right-radius: 10px;
              border-top-left-radius: 10px;
            }

            h3 {
              color: #03045e;
              font-family: "Plus Jakarta Sans";
              font-size: 28.548px;
              font-style: normal;
              font-weight: 700;
              line-height: 144%;
              /* 41.109px */
              letter-spacing: -0.856px;
              margin-bottom: 11px;
              margin-top: 23px;
              padding: 0 34px;
            }

            p {
              color: #4b4d8e;
              font-family: "Plus Jakarta Sans";
              font-size: 21.411px;
              font-style: normal;
              font-weight: 500;
              line-height: 158%;
              /* 33.83px */
              padding: 0 34px 43px;
              margin: 0;
            }

            a.btn {
              border-radius: 9px;
              background: #2d74ff;
              height: 76px;
              display: flex;
              align-items: center;
              width: calc(100% - 40px);
              margin-left: 20px;
              text-decoration: none;
              justify-content: center;
              color: #fff;
              font-family: "Plus Jakarta Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              /* 24px */
              letter-spacing: -0.6px;
              margin-bottom: 30px;
            }
          }

          .card.contact-card {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }

    .a-section4 {
      padding-top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        max-width: 1440px;
        align-items: center;
        flex-wrap: wrap;

        h2 {
          color: #03045e;
          font-size: 48.77px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          /* 58.524px */
          letter-spacing: -1.463px;
          max-width: 653px;
          margin: auto;

          span {
            color: #ff4c98;
            position: relative;
            margin-left: 10px;

            svg {
              position: absolute;
              bottom: -10px;
              left: 4px;
            }
          }
        }
      }

      @keyframes marquee {
        from {
          transform: translateX(0);
        }

        to {
          transform: translateX(-50%);
        }
      }

      .marquee-container {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 30px;

        .marquee-content {
          display: inline-block;
          animation: marquee 22s linear infinite;


          img {
            flex-shrink: 0;
            width: auto;
            height: 400px;
            margin-right: 30px;
          }
        }
      }
    }

    .a-section5 {
      padding: 76px 20px 0;
      background: #3a2771;

      .wrapper {
        display: flex;
        max-width: 1400px;
        margin: auto;
        align-items: center;
        flex-wrap: wrap;

        h2 {
          color: #fff;
          text-align: center;
          font-family: "Plus Jakarta Sans";
          font-size: 38px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          letter-spacing: -1.44px;
          max-width: 600px;
          margin: auto;
          margin-bottom: 56px;
        }

        .cards-wrapper {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 32px;

          .card {
            width: calc(33.33% - 22px);

            img {
              width: 100%;
              border-radius: 10px;
            }

            h3 {
              color: #fff;
              font-family: "Plus Jakarta Sans";
              font-size: 26px;
              font-style: normal;
              font-weight: 700;
              line-height: 144%;
              letter-spacing: -0.856px;
              margin-bottom: 14px;
              margin-top: 20px;
              padding: 0;
            }

            p {
              color: #fff;
              font-family: "Plus Jakarta Sans";
              font-size: 21.411px;
              font-style: normal;
              font-weight: 500;
              line-height: 158%;
              padding: 0 0 20px;
              margin: 0;
            }

            a {
              color: #ff8abc;
              font-family: "Plus Jakarta Sans";
              font-size: 18px;
              font-style: normal;
              font-weight: 800;
              line-height: 120%;
              letter-spacing: -0.54px;
              text-decoration: none;
              padding: 0 0 75px;
              display: inline-flex;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .home-page {
    .a-heading1 {
      font-size: 40px;
      margin: 10px 20px;

      svg {
        display: none;
      }
    }

    .a-heading2 {
      font-size: 18px;
      margin: auto 20px;
    }

    .a-section1 {
      margin: 20px;

      .wrapper {
        flex-direction: column;

        img {
          max-width: calc(100% - 40px);
          margin-left: 20px;
        }

        .img-wrapper {
          .content-wrapper {
            width: calc(100% - 40px);
            left: 20px;
            bottom: 20px;

            p {
              font-size: 16px;
            }

            a {
              font-size: 14px;
            }

            a.btn {
              font-size: 14px;
              height: 50px;
            }
          }
        }

        .img1 {
          margin-top: 0;
        }

        .img3 {
          margin-top: 0;
        }
      }
    }

    .a-section2 {
      margin: 20px;

      .wrapper {
        flex-direction: column;

        img {
          max-width: calc(100% - 40px);
          margin-left: 20px;
        }

        .left {
          h2 {
            font-size: 30px;
            margin: 10px 20px;

            svg {
              display: none;
            }
          }

          p {
            font-size: 16px;
            margin: 10px 20px;
          }

          a.btn {
            font-size: 16px;
            margin: 10px 20px;
          }
        }

        .right {
          .img-wrapper {
            .content-wrapper {
              width: calc(70% - 40px);
              padding: 10px;
              left: 20px;
              bottom: 4px;

              .type1 {
                font-size: 16px;
              }

              .type2 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .a-section3 {
      margin: 0;
      padding: 40px 0;

      .wrapper {
        margin: 20px;

        h2 {
          font-size: 30px;
          margin-bottom: 20px;

          svg {
            display: none;
          }
        }

        .cards-wrapper {
          .card {
            width: calc(50% - 20px);

            h3 {
              font-size: 20px;
            }

            p {
              font-size: 16px;
            }

            a.btn {
              font-size: 16px;
              height: 40px;
            }
          }
        }
      }
    }

    .a-section4 {

      .wrapper {

        width: 100%;

        .edit {
          width: 100%;

          .leftDesign {
            height: 200px;
            width: 50px;
          }

          .rightDesign {
            height: 200px;
            width: 50px;

          }

          .contentslide {
            width: 70%;

            .middleContent {
              .slide-wrapper {
                .slide {
                  padding: 20px;

                  p {
                    font-size: 16px;
                  }
                }

                strong {
                  font-size: 20px;
                }
              }
            }

            .left-click {
              font-size: 30px;
              margin-left: 20px;
            }

            .right-click {
              font-size: 30px;
              right: 20px;
            }
          }
        }
      }
    }

    .a-section5 {
      margin: 0;
      padding: 40px 0 0;

      .wrapper {
        margin: 20px;

        h2 {
          font-size: 30px;
          margin-bottom: 20px;
        }

        .cards-wrapper {
          gap: 0;

          .card {
            width: 100%;

            h3 {
              font-size: 20px;
            }

            p {
              font-size: 16px;
            }

            a {
              font-size: 16px;
            }
          }
        }
      }
    }

    .a-section6 {
      margin: 20px;

      .wrapper {
        flex-direction: column;

        img {
          max-width: 100%;
        }

        .right {
          h2 {
            font-size: 30px;
            margin: 10px 0 30px;
          }

          .input-wrapper {
            margin: 0;
          }

          button {
            margin: 0;
          }
        }

        .left {
          .img-wrapper {
            .content-wrapper {
              width: 100%;
              padding: 10px;
              margin: 0;
              border-radius: 10px;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .home-page {
    .a-section3 {
      .wrapper {
        .cards-wrapper {
          gap: 0;

          .card {
            width: calc(100%);
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {

  .home-page {
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .a-heading1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        text-align: center;
        margin: 0px;
        font-size: 45px;
      }

      .a-section2 {
        .wrapper {
          .right {
            .img-wrapper {
              img{
                width: 100%;
              }
              .content-wrapper {
                width: auto;
                height: 100px;
                padding: 15px 15px;
                .type1 {
                  font-size: 30px;
                }
                .type2 {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .a-section3 {
        .wrapper {
          .cards-wrapper {
            flex-direction: column;
            width: 100%;

            .card {
              width: 100%;
            }
          }
        }
      }

      .a-section4 {
        padding: 0px;

        .wrapper {

          h2 {
            font-size: 28px;
            padding: 10px;

            svg {
              width: 150px;
            }
          }
        }
        @keyframes marquee {
          from {
            transform: translateX(0);
          }
  
          to {
            transform: translateX(-400%);
          }
        }

        .marquee-container {
          width: auto;
          .marquee-content {
            display: inline-block;
            animation: marquee 13s linear infinite;
  
  
            img {
              width: 75%;
              height: 200px;
              margin-right: 20px;
            }
          }
        }
      }

      .a-section5{
        .wrapper {
          .cards-wrapper {
            flex-direction: column;
            .card {
              width: 100%;
              h3 {
                font-size: 20px;
              }

              p {
                font-size: 16px;
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}