.review-wrapper {
  display: flex;
  justify-content: center;
  padding: 76px 0px ;
  position: relative;
  width: 100%;

  .wrapper {
    width: 90%;
    max-width: 1440px;

    .boxes {
      display: flex;
      gap: 50px;

      .review {
        display: flex;
        flex-direction: column;
        border-radius: 28px;
        background: #FFF;
        padding: 36px 37px;
        width: 50%;

        .stars {
          display: flex;
          gap: 5px;
        }

        p {
          overflow: hidden;
          color: #4B4D8E;
          text-overflow: ellipsis;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 158%;
        }

        .read-more {
          overflow: hidden;
          color: #03045E;
          text-overflow: ellipsis;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 34px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {

  .review-wrapper {
    padding: 76px 0px 0px 0px;
    margin-bottom: 40px;

    .wrapper {
      .boxes {
        flex-direction: column;
        gap: 50px;

        .review {
          width: 100%;
        }
      }
    }
  }

}

@media screen and (max-width: 430px) {

  .review-wrapper {
    padding: 56px 0px 0px 0px;

    .wrapper {
      .boxes {
        flex-direction: column;
        gap: 50px;

        .review {
          width: 100%;
        }
      }
    }
  }

}