.v-policy-wrapper {

    .section1 {

        .container1 {
            margin-top: 5%;
            background-color: #eaf2ff;
            border-radius: 10px;
            margin: 20px;
            height: 300px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #002366;
            font-size: 40px;
            font-weight: 600;
            padding: 98px 0px;
            position: relative;
        }

    }

    /* section 2 */

    .section2 {
        box-sizing: border-box;
        padding: 50px 0px;
        position: relative;
        display: flex;
        justify-content: center;

        .textData {
            width: 100%;
            max-width: 1600px;
            padding: 0px 40px;

            .text {
                margin: 15px 0px;
            }

            h2 {
                color: #03045e;
                font-size: 40px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                letter-spacing: -1.883px;
                margin: 0px;
            }

            h3 {
                box-sizing: border-box;
                color: #03045e;
                font-size: 25px;
                font-weight: 600;
                line-height: 52px;
                text-align: left;
                margin: 0px;
            }

            li, .text {
                color: rgba(3, 4, 94, 0.7);
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 158%;
            }

            .boldText {
                box-sizing: border-box;
                color: #03045e;
                display: inline;
                font-size: 20px;
                font-weight: 700;
                line-height: 30.6px;
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .v-policy-wrapper {
        .section1 {
            .container1 {
                margin: 20px 0px;
                padding: 20px;
                height: 200px;
                font-size: 30px;
            }
        }

        .section2 {
            .textData {
                padding: 0px 20px;

                h2 {
                    font-size: 30px;
                }

                h3 {
                    font-size: 20px;
                    line-height: 30px;
                }

                li, .text {
                    font-size: 16px;
                    line-height: 24px;
                }

                .boldText {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .v-policy-wrapper {
        .section1 {
            .container1 {
                height: 150px;
                font-size: 20px;
            }
        }

        .section2 {
            .textData {
                padding: 0px 10px;

                h2 {
                    font-size: 25px;
                }

                h3 {
                    font-size: 18px;
                    line-height: 25px;
                }

                li, .text {
                    font-size: 14px;
                    line-height: 20px;
                }

                .boldText {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }
}
