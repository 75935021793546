.message-wrapper {
    margin: 80px 20px 80px;

    .wrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        max-width: 1400px;
        margin: auto;

        .left {
            .img-wrapper {
                position: relative;

                .content-wrapper {
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - 20px);
                    border-radius: 20px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    background: #3a2771;
                    padding: 32px;
                    margin: 0 10px 10px;
                    display: flex;
                    gap: 20px;
                    align-items: center;

                    p {
                        margin: 0;
                    }

                    .icon {
                        background-color: #ffd1e5;
                        height: 66px;
                        width: 66px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        svg {
                            margin-left: -4px;
                            margin-top: 3px;
                        }
                    }

                    .type2 {
                        color: #fff;
                        font-family: "Plus Jakarta Sans";
                        font-size: 20.743px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: -0.622px;
                        margin-top: 15px;
                        max-width: 330px;
                    }
                }
            }
        }

        .right {
            width: 40%;
            margin: auto;

            .right-wrapper {

                h2 {
                    color: #03045e;
                    font-family: "Plus Jakarta Sans";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 120%;
                    letter-spacing: -1.44px;
                    margin-top: 0;

                    span {
                        color: #ff4c98;
                    }
                }

                .input-wrapper {
                    width: 100%;

                    label {
                        color: #414141;
                        font-family: "Plus Jakarta Sans";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 160%;
                        letter-spacing: -0.3px;

                        span {
                            color: #ff4c98;
                        }
                    }

                    label,
                    input,
                    textarea {
                        width: 100%;
                    }

                    input,
                    textarea {
                        display: flex;
                        padding: 12px 20px;
                        align-items: center;
                        gap: 8px;
                        align-self: stretch;
                        border-radius: 10px;
                        border: 1px solid rgba(203, 214, 231, 0);
                        background: #fff;
                        color: #000;
                        font-family: "Plus Jakarta Sans";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                        letter-spacing: -0.27px;
                        margin: 8px 0 30px;
                    }

                    input::placeholder,
                    textarea::placeholder {
                        color: #94a3b8;
                    }

                    input {

                        &.valid {
                            border-color: green;
                        }

                        &.invalid {
                            border-color: red;
                        }
                    }

                    .errorMessage {
                        color: red;
                    }


                    textarea {
                        resize: none;
                    }
                }

                button {
                    border-radius: 9px;
                    background: #2d74ff;
                    height: 76px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    color: #fff;
                    font-family: "Plus Jakarta Sans";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: -0.6px;
                    border: none;
                    margin-top: 7px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .message-wrapper {

        .wrapper {
            flex-direction: column;

            .left{
                width: 100%;
                .img-wrapper {

                    img{
                        width: 100%;
                    }
                }
            }
            .right{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .message-wrapper {
        .wrapper {
            .left {
                .img-wrapper {
                    .content-wrapper{
                        .type2{
                        font-size: 20px;
                    }}
                }
            }
        }
    }
}