@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.a-footer {
    background: #3a2771;

    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    .wrapper {
        max-width: 1600px;
        width: 100%;
        padding: 24px 40px;

        .content-wrapper {
            border-bottom: 1px solid rgba(255, 255, 255, 0.21);
            display: flex;
            flex-direction: column;
            gap: 50px;
            padding: 24px 0px 24px 0px;

            .row {
                display: flex;
                justify-content: space-between;

                .left {
                    justify-content: flex-start;

                    .image {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 80%;

                        .ndis {
                            border-left: 1px solid rgba(255, 255, 255, 0.21);
                            padding-left: 20px;
                            height: 60px;
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .right {
                    justify-content: flex-end;
                }

                .left,
                .right {
                    display: flex;
                    flex-wrap: wrap;
                    width: 50%;
                    margin-bottom: 20px;
                }


            }

            .row1 {
                .icon {
                    height: 60px;
                    width: 60px;
                    background-color: #ffd1e5;
                    border-radius: 50%;
                    margin-right: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .row2 {
                color: #fff;
                font-family: 'Plus Jakarta Sans';
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 160%;
                letter-spacing: -0.33px;

                .item {
                    margin-right: 105px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .right {
                    .item {
                        margin-right: 0;
                    }

                    nav {
                        width: 100%;

                        ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                            display: flex;
                            flex-wrap: wrap;
                            gap: 51px;
                            justify-content: flex-end;

                            li {
                                padding: 0;
                            }
                        }
                    }
                }

                a {
                    color: #ff8abc;
                    text-decoration: none;
                    margin-top: 14px;
                    display: inline-flex;
                }
            }

            .row3 {
                color: rgba(255, 255, 255, 0.63);
                font-family: 'Plus Jakarta Sans';
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%;
                letter-spacing: -0.27px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                a {
                    color: inherit;
                    text-decoration: none;
                    margin-right: 34px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .left {
                    gap: 32px;

                    .circle {
                        background-color: #FFD1E5;
                        width: 51.966px;
                        height: 51.966px;
                        flex-shrink: 0;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 21.653px;
                                height: 21.653px;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

            }
        }

        .copyright {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                color: rgba(255, 255, 255, 0.63);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: -0.27px;
            }
        }
    }
}




@media screen and (max-width: 1024px) {
    .a-footer {
        .wrapper {
            .content-wrapper {
                margin: 0px 20px;
                gap: 30px;

                .row {

                    .left,
                    .right {
                        width: 100%;
                    }

                    .left {

                        .image {
                            width: 100%;
                            align-items: center;
                            gap: 20px;

                            .ndis {
                                margin-bottom: 0;
                                padding-bottom: 0px;
                            }

                        }
                    }
                }

                .row1 {
                    .icon {
                        margin-right: 20px;
                    }
                }

                .row2 {

                    .left {
                        flex-direction: column;

                        .item {
                            margin-right: 0;
                        }
                    }

                    .right {
                        nav {
                            display: flex;
                            justify-content: end;

                            ul {
                                display: flex;
                                flex-direction: column;
                                gap: 0px;
                            }
                        }
                    }
                }

                .row3 {
                    .left {
                        gap: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .a-footer {
        .wrapper {
            .content-wrapper {
                .row1 {
                    .left {
                        .image {
                            flex-direction: column;
                            display: flex;
                            align-items: baseline;
                            gap: 0px;

                            .ndis {
                                padding-left: 0;
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .a-footer {
        .wrapper {
            padding: 20px;

            .content-wrapper {
                margin: 0px;
                gap: 0px;

                .row1 {
                    .left {
                        .image {
                            .logo {
                                width: 200px;
                            }

                            .ndis{
                                img{
                                    width: 200px;
                                }
                            }
                        }
                    }
                }

                .row2 {
                    flex-direction: column;

                    .right {
                        justify-content: flex-start;

                        nav {
                            justify-content: flex-start;

                            ul {
                                gap: 0px;
                            }
                        }
                    }
                }

                .row3 {
                    flex-direction: column;

                    .left {
                        gap: 10px;
                    }

                    .right {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}