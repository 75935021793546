.main-services {
  .a-breadcrumb {
    max-width: 1400px;
    margin: 36px auto 48px;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        padding: 0;
        a,
        span {
          color: rgba(3, 4, 94, 0.7);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 31px;
          text-decoration: none;
        }
        span {
          margin-left: 12px;
        }
      }
    }
  }
  .a-section1 {

    margin: 0 20px;

    .wrapper {

      gap: 30px;
      max-width: 1400px;
      margin: auto;

      h1 {
        color: #03045e;
        font-size: 57px;
        font-style: normal;
        font-weight: 800;
        line-height: 70px;
        letter-spacing: -1.71px;
        margin: 0;

        div {
          color: #ff4c98;
        }

      }

      p {
        color: rgba(3, 4, 94, 0.7);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 42px;
        margin: 25px 0 38px;
        width: 1086px;
        max-width: 100%;
      }

     

      img {
        width: 100%;
      }

    }
  }

  .button {
    display: inline-flex;
    width: 176px;
    padding: 14px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #2d74ff;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    margin-bottom: 64px;
  }

  .a-section2 {
    margin: 129px 20px;

    .wrapper {

      display: flex;
      justify-content: space-between;
      gap: 33px;
      max-width: 1400px;
      margin: auto;
      align-items: center;
      flex-wrap: wrap;

      .left {

        width: calc(60% - 33px);

        h2 {
          color: #03045e;
          font-size: 38px;
          font-style: normal;
          font-weight: 700;
          line-height: 47px;
          letter-spacing: -1.14px;
          width: 539px;
          max-width: 100%;
        }

        p {
          color: rgba(3, 4, 94, 0.7);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 35px;
        }

        ul {

          padding-left: 30px;

          li {

            color: rgba(3, 4, 94, 0.7);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;

          }
        }
      }
      
      .right {

        width: 40%;
        position: relative;

        img {
          width: 100%;
          margin-top: -72px;

          .content-wrapper {
            border-radius: 23px;
            background: #3a2771;
            padding: 43px 35px 47px;
            position: absolute;
            width: 100%;
            bottom: -174px;

            h2 {
              color: #ff8abc;
              font-size: 38.25px;
              font-style: normal;
              font-weight: 700;
              line-height: 45px;
              letter-spacing: -1.147px;
              margin: 0;
            }

            p {
              color: #fff;
              font-size: 20.743px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: -0.622px;
              width: 380px;
              max-width: 100%;
              margin: 14px 0 36px;
            }
          }
        }
      }
    }
  }

  .a-section3 {

    padding: 76px 20px;
    background: #3a2771;

    .wrapper {

      display: flex;
      max-width: 1400px;
      margin: auto;
      align-items: center;
      flex-wrap: wrap;

      h2 {

        color: #fff;
        text-align: center;
        font-size: 58.77px;
        font-style: normal;
        font-weight: 800;
        line-height: 70px;
        letter-spacing: -1.763px;
        text-align: center;
        width: 100%;
        margin-bottom: 100px;

        div {
          color: #ff8abc;
        }
      }

      .cards-wrapper {

        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        .card {

          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left {
            text-align: left;
          }
          .right {
            display: flex;
            flex-direction: column;
          }
          h3 {
            color: #fff;
            font-size: 42px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            /* 50.4px */
            letter-spacing: -1.26px;
            text-align: left;
            max-width: 565px;
            margin: 0;
            margin-bottom: 18px;
          }
          p {
            color: rgba(255, 255, 255, 0.7);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 175%;
            /* 35px */
            text-align: left;
            max-width: 565px;
            margin: 0;
          }
        }
      }
    }
  }
  .a-section4 {
    margin: 60px 20px;
    .wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      max-width: 1400px;
      margin: auto;
      align-items: center;
      flex-wrap: wrap;
      h2 {
        color: #03045e;
        font-size: 48.77px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
        /* 58.524px */
        letter-spacing: -1.463px;
        max-width: 653px;
        margin: auto;
        span {
          color: #ff4c98;
          position: relative;
          margin-left: 10px;
          svg {
            position: absolute;
            bottom: -10px;
            left: 4px;
          }
        }
      }
      .carousel-wrapper {
        margin-top: 50px;
        overflow: hidden;
        .image-carousel {
          display: flex;
          align-items: center;
          .item {
            width: 100%;
            min-width: 100%;
            display: flex;
          }
          img {
            border-radius: 10px;
          }
          .owl-item {
            transform: scale(0.85);
          }
          .owl-item.active.center {
            transform: scale(1);
          }
        }
        .content-wrapper {
          display: flex;
          align-items: center;
          .leftDesignWrapper,
          .rightDesignWrapper {
            width: 20%;
            position: relative;
          }
          .rightDesignWrapper {
            display: flex;
            justify-content: end;
          }
          .leftDesign,
          .rightDesign {
            height: 236px;
            width: 134px;
            border-radius: 51px;
            background: #3a2771;
            z-index: 1;
          }
          .leftDesign {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
          .rightDesign {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
          }
          .leftButton,
          .rightButton {
            position: absolute;
            top: 35%;
            background-color: transparent;
            border: none;
            cursor: pointer;
            z-index: 1;
          }
          .leftButton {
            right: 20px;
          }
          .rightButton {
            left: 20px;
          }
          .content-carousel {
            padding: 40px 0;
            display: flex;
            width: 60%;
            /* transform: translateX(-100%); */
            transition: all 0.5s;
            .item {
              width: 100%;
              min-width: 100%;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              /* opacity: 0; */
              p {
                color: #4b4d8e;
                text-align: center;
                font-family: "Plus Jakarta Sans";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 158%;
                /* 37.92px */
                margin: 0;
              }
              p {
                max-width: 860px;
              }
              img {
                width: auto;
              }
              .name {
                width: 100%;
                text-align: center;
                color: #03045e;
                font-family: "Plus Jakarta Sans";
                font-size: 28.548px;
                font-style: normal;
                font-weight: 700;
                line-height: 144%;
                /* 41.109px */
                letter-spacing: -0.856px;
                padding: 25px 0;
              }
            }
            .item.active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .a-section6 {
    margin: 80px 20px 80px;
    .wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      max-width: 1400px;
      margin: auto;
      .left {
        .img-wrapper {
          position: relative;
          .content-wrapper {
            position: absolute;
            bottom: 0;
            width: calc(100% - 20px);
            border-radius: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            background: #3a2771;
            padding: 32px;
            margin: 0 10px 10px;
            display: flex;
            gap: 20px;
            align-items: center;
            p {
              margin: 0;
            }
            .icon {
              background-color: #ffd1e5;
              height: 66px;
              width: 66px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                margin-left: -4px;
                margin-top: 3px;
              }
            }
            .type2 {
              color: #fff;
              font-family: "Plus Jakarta Sans";
              font-size: 20.743px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.622px;
              margin-top: 15px;
              max-width: 330px;
            }
          }
        }
      }
      .right {
        max-width: 599px;
        margin: auto;
        h2 {
          color: #03045e;
          font-family: "Plus Jakarta Sans";
          font-size: 48px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          letter-spacing: -1.44px;
          margin-top: 0;
          span {
            color: #ff4c98;
          }
        }
        .input-wrapper {
          width: 100%;
          label {
            width: 100%;
            color: #414141;
            font-family: "Plus Jakarta Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: -0.3px;
            span {
              color: #ff4c98;
            }
          }
          input {
            width: 100%;
            display: flex;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid rgba(203, 214, 231, 0);
            background: #fff;
            color: #000;
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.27px;
            margin: 8px 0 30px;
            &::placeholder {
              color: #94a3b8;
            }
          }
          textarea {
            width: 100%;
            display: flex;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid rgba(203, 214, 231, 0);
            background: #fff;
            color: #000;
            font-family: "Plus Jakarta Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.27px;
            margin: 8px 0 30px;
            &::placeholder {
              color: #94a3b8;
            }
          }
        }
        button {
          border-radius: 9px;
          background: #2d74ff;
          height: 76px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          color: #fff;
          font-family: "Plus Jakarta Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: -0.6px;
          border: none;
          margin-top: 7px;
        }
      }
    }
  }
}


@media screen and (max-width: 1024px) {
  .main-services {
    .a-breadcrumb {
      margin: 20px;
    }
    .a-section1 {
      margin: 20px;
      h1 {
        font-size: 42px;
      }
      p {
        font-size: 20px;
        padding: 0;
        margin: 16px auto;
      }
      a.btn {
        margin-bottom: 24px;
      }
    }
    .a-section2 {
      margin: 40px 20px;
      .wrapper {
        gap: 20px;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
          img {
            margin-top: 0;
          }
          .content-wrapper {
            padding: 20px;
            bottom: 0;
            h2 {
              font-size: 22px;
            }
            p {
              font-size: 16px;
              margin: 12px 0;
            }
            a.btn {
              padding: 8px;
              font-size: 16px;
            }
          }
        }
        h2 {
          font-size: 24px;
        }
        p {
          font-size: 18px;
        }
      }
    }
    .a-section3 {
      margin: 0;
      padding: 40px 0;
      .wrapper {
        margin: 20px;
        h2 {
          margin-bottom: 36px;
          font-size: 30px;
        }
        .cards-wrapper {
          .card {
            flex-direction: column;
            justify-content: center;
            .col {
              width: 100%;
              img {
                width: 100%;
              }
            }
            .col.right {
              justify-content: center;
              text-align: center;
            }
            .col.left {
              justify-content: center;
              text-align: center;
            }
            .col.order1 {
              order: 1;
            }
            .col.order2 {
              order: 2;
            }
            h3 {
              font-size: 20px;
              text-align: center;
              max-width: 100%;
              margin-top: 20px;
            }
            p {
              font-size: 16px;
              text-align: center;
              max-width: 100%;
            }
          }
        }
      }
    }
    .a-section4 {
      margin: 0;
      padding: 40px 0;
      .wrapper {
        margin: 0;
        .carousel-wrapper {
          .content-wrapper {
            .leftDesignWrapper {
              width: 15%;
            }
            .rightDesignWrapper {
              width: 15%;
            }
            .leftButton {
              right: -13px;
            }
            .rightButton {
              left: -13px;
            }
            .leftDesign {
              width: 70px;
            }
            .rightDesign {
              width: 70px;
            }
            .content-carousel {
              width: 70%;
            }
          }
        }
      }
    }
    .a-section6 {
      margin: 20px;
      .wrapper {
        flex-direction: column;
        img {
          max-width: 100%;
        }
        .right {
          h2 {
            font-size: 30px;
            margin: 10px 20px 30px;
          }
          .input-wrapper {
            margin: 0 20px;
          }
          button {
            margin: 0 20px;
          }
        }
        .left {
          .img-wrapper {
            .content-wrapper {
              width: 100%;
              padding: 10px;
              margin: 0;
              border-radius: 10px;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .main-services {
    .a-section1 {
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
    .a-section3 {
      .wrapper {
        .cards-wrapper {
          gap: 0;
          .card {
            width: calc(100%);
            margin-bottom: 20px;
          }
        }
      }
    }
    .a-section4 {
      .wrapper {
        .carousel-wrapper {
          .content-wrapper {
            .leftDesignWrapper {
              width: 20%;
            }
            .rightDesignWrapper {
              width: 20%;
            }
            .leftButton {
              right: -13px;
            }
            .rightButton {
              left: -13px;
            }
            .leftDesign {
              width: 40px;
            }
            .rightDesign {
              width: 40px;
            }
            .content-carousel {
              width: 60%;
            }
          }
        }
      }
    }
  }
}
