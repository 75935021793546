.main-contact {
  $color_1: #03045e;
  $color_2: rgba(3, 4, 94, 0.7);
  $color_3: #ff4c98;
  $color_4: #2d74ff;
  $color_5: #ff8abc;
  $color_6: rgba(255, 255, 255, 0.7);
  $color_7: #fff;
  $color_8: #000;
  $color_9: #94a3b8;
  $font-family_1: "Plus Jakarta Sans";
  $background-color_1: #3a2771;
  $background-color_2: #422d81;
  $background-color_3: #ffd1e5;

  .a-section1 {
    margin: 0 20px;
    .wrapper {
      gap: 30px;
      max-width: 1400px;
      margin: auto;
    }
    h1 {
      color: $color_1;
      text-align: center;
      font-size: 57px;
      font-style: normal;
      font-weight: 800;
      line-height: 124%;
      letter-spacing: -1.71px;
    }
    p {
      color: $color_2;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 158%;
      max-width: 691px;
      margin: 24px auto;
    }
    .cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 32px;
      margin-top: 89px;
      .card {
        width: calc(50% - 16px);
        padding: 43px 52px;
        border-radius: 19px;
        background: #fff;
      }
      h3 {
        color: $color_3;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%;
        letter-spacing: -1.08px;
        margin: 0;
        margin-bottom: 52px;
      }
      .contact-info {
        color: $color_1;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 158%;
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $color_4;
        }
      }
      .business-hours {
        color: $color_1;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 158%;
        margin-bottom: 22px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .a-section2 {
    margin: 80px 0 60px;
    padding: 80px 0;
    background-color: $background-color_1;
    .wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      max-width: 1400px;
      margin: auto;
      align-items: unset;
      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
          color: $color_5;
          font-size: 62.769px;
          font-style: normal;
          font-weight: 800;
          line-height: 120%;
          letter-spacing: -1.883px;
          max-width: 420px;
          margin: 0;
        }
        p {
          color: $color_6;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 158%;
          margin: 0;
          margin-top: 48px;
        }
        .connect-with-us-wrapper {
          display: flex;
          justify-content: space-between;
          background-color: $background-color_2;
          height: 205px;
          align-items: center;
          padding: 40px;
          border-radius: 12px;
          flex-wrap: wrap;
          div {
            color: $color_5;
            font-size: 36px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            letter-spacing: -1.08px;
          }
          .icon {
            width: 60px;
            height: 60px;
            background-color: $background-color_3;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .right {
        max-width: 599px;
        margin: auto;
        width: 50%;
        .input-wrapper {
          width: 100%;
          label {
            width: 100%;
            color: $color_7;
            font-family: $font-family_1;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: -0.3px;
            span {
              color: $color_3;
            }
          }
          input {
            width: 100%;
            display: flex;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid rgba(203, 214, 231, 0);
            background: #fff;
            color: $color_8;
            font-family: $font-family_1;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.27px;
            margin: 8px 0 30px;
            &::placeholder {
              color: $color_9;
            }
          }
          textarea {
            width: 100%;
            display: flex;
            padding: 12px 20px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid rgba(203, 214, 231, 0);
            background: #fff;
            color: $color_8;
            font-family: $font-family_1;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.27px;
            margin: 8px 0 30px;
            &::placeholder {
              color: $color_9;
            }
          }
        }
        button {
          border-radius: 9px;
          background: #2d74ff;
          height: 76px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          color: $color_7;
          font-family: $font-family_1;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: -0.6px;
          border: none;
          margin-top: 7px;
        }
      }
    }
  }
  .a-section3 {
    margin: 40px 20px;
    .wrapper {
      gap: 30px;
      max-width: 1400px;
      margin: auto;
      iframe {
        width: 100%;
        height: 750px;
        border: none;
        border-radius: 22px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .main-contact {
    .a-section1 {
      margin: 20px;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
      .cards-wrapper {
        margin-top: 36px;
        .card {
          width: 100%;
          padding: 20px;
        }
        h3 {
          font-size: 24px;
          margin-bottom: 24px;
        }
        .contact-info {
          font-size: 20px;
        }
        .business-hours {
          font-size: 20px;
        }
      }
    }
    .a-section2 {
      margin: 0;
      padding: 20px;
      .wrapper {
        flex-direction: column;
        .left {
          width: 100%;
          h2 {
            font-size: 30px;
          }
          p {
            font-size: 24px;
            margin: 20px 0;
          }
          .connect-with-us-wrapper {
            div {
              font-size: 24px;
              width: 100%;
            }
          }
          .img-wrapper {
            .content-wrapper {
              width: 100%;
              padding: 10px;
              margin: 0;
              border-radius: 10px;
              border-top-right-radius: 0;
              border-top-left-radius: 0;
            }
          }
        }
        .right {
          width: 100%;
          max-width: 100%;
          margin: 0;
          h2 {
            font-size: 30px;
            margin: 10px 20px 30px;
          }
          .input-wrapper {
            margin: 0;
          }
          button {
            margin: 0;
          }
        }
      }
    }
    .a-section3 {
      .wrapper {
        iframe {
          height: 400px;
        }
      }
    }
  }
}
